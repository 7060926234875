<template>
  <div style="padding: 20px; background: #FFFFFF" v-loading="loading">
    <el-form  size="small" ref="form" :model="form" label-width="80px">
      <el-form-item label="项目名称">
        <el-input v-model="form.pname"></el-input>
      </el-form-item>
      <el-form-item label="主题上限">
        <el-select v-model="form.maxMsCount" placeholder="请选择主题数量上限">
          <el-option label="无限" :value="-1"></el-option>
          <el-option label="1个" :value="1"></el-option>
          <el-option label="2个" :value="2"></el-option>
          <el-option label="3个" :value="3"></el-option>
          <el-option label="5个" :value="5"></el-option>
          <el-option label="10个" :value="10"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择主题">
        <el-transfer v-loading="transferLoading" :titles="['备选', '已选']" v-model="form.msIdArray" :data="msArray"></el-transfer>
      </el-form-item>
      <el-form-item label="新建方案">
        <el-switch
          v-model="form.allowAddMs"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </el-form-item>
      <el-form-item>
        <el-button v-show="$route.meta.type === 'add'" type="primary" @click="submitAdd">立即创建</el-button>
        <el-button v-show="$route.meta.type === 'edit'" type="primary" @click="submitEdit">保存修改</el-button>
        <el-button @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { listMS, addCP, getDetail, updateCP } from '@/api/cProject'
export default {
  name: 'name',
  data () {
    return ({
      loading: false,
      transferLoading: true,
      msArray: [],
      form: {
        pname: '',
        maxMsCount: 2,
        msIdArray: [],
        allowAddMs: false
      }
    })
  },
  mounted () {
    this.fetchMS()
    if (this.$route.meta.type === 'edit') {
      this.fetchDetail()
    }
  },
  methods: {
    async fetchDetail () {
      this.loading = true
      const res = await getDetail({ id: this.$route.params.id })
      this.loading = false
      if (res.code === 0) {
        this.form = res.data
      }
    },
    async fetchMS () {
      const res = await listMS()
      this.transferLoading = false
      this.msArray = res.data
    },
    async submitAdd () {
      if (!this.form.pname) {
        this.$message.warning('请填写项目名称')
        return
      }
      if (this.form.maxMsCount !== -1 && this.form.msIdArray.length > this.form.maxMsCount) {
        this.$message.warning('已选主题数目已超过设置上限')
        return
      }
      this.loading = true
      const res = await addCP(this.form)
      this.loading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.$router.back()
      } else {
        this.$message.error(res.msg)
      }
    },
    async submitEdit () {
      if (!this.form.pname) {
        this.$message.warning('请填写项目名称')
        return
      }
      if (this.form.maxMsCount !== -1 && this.form.msIdArray.length > this.form.maxMsCount) {
        this.$message.warning('已选主题数目已超过设置上限')
        return
      }
      this.loading = true
      const res = await updateCP(this.form)
      this.loading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.$router.back()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
